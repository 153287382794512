import React from 'react'
import tw, { css } from 'twin.macro'

import { GatsbyImage } from 'gatsby-plugin-image'

const handleAccordianClick = (e) => {
  e.target.nextSibling.classList.toggle('active')
  e.target.classList.toggle('active')
}

const AccordianButton = ({ children }) => {
  return (
    <button
      css={[
        tw`text-2xl font-bold border-b-2 w-full pb-3 mb-3 text-left flex justify-between items-center`,
        css`
          &:focus {
            outline: none;
          }
          &:hover {
            color: gray;
          }
          &:hover::after {
            color: gray;
            border-color: gray;
          }
          &::after {
            ${tw`font-bold text-navy border-navy border-2 inline-block rounded-full text-center text-2xl`}
            line-height: 0.9;
            content: '+';
            height: 31px;
            width: 31px;
          }
          &.active::after {
            ${tw`font-bold text-navy border-navy border-2 inline-block rounded-full text-center text-2xl`}
            line-height: 0.9;
            content: '-';
            height: 31px;
            width: 31px;
          }
        `,
      ]}
      onClick={handleAccordianClick}
    >
      {children}
    </button>
  )
}

const AccordianElement = ({ children, index }) => (
  <table
    className={`${index === 0 && 'active'} accordianData`}
    css={css`
      & {
        display: none;
        ${tw`my-4`}
      }
      &.active {
        display: block;
      }
    `}
  >
    <tbody>{children}</tbody>
  </table>
)

export default function Faq(props) {
  const { text, image, rubrik } = props.content

  return (
    <section css={tw`container md:px-10 px-6`}>
      <AccordianButton>{rubrik}</AccordianButton>
      <AccordianElement>
        <tr css={tw`max-w-4xl`}>
          {image[0]?.localFile?.childImageSharp?.gatsbyImageData && (
            <GatsbyImage
              image={image[0]?.localFile?.childImageSharp?.gatsbyImageData}
              alt={image[0]?.title}
            />
          )}
          <td dangerouslySetInnerHTML={{ __html: text }}></td>
        </tr>
      </AccordianElement>
    </section>
  )
}
